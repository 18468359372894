import { createContext, createMemo, createRenderEffect, splitProps, useContext } from 'solid-js';
import { isServer, ssr, useAssets } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { icons } from './icons';
import type { ParentProps } from 'solid-js';
import type { IconProps } from './props';

export type IconName = keyof typeof icons;

export { icons };
const mapId = 'theiconmap';

function initClientProvider() {
	const availableIcons = new Set<IconName>();
	let map = document.getElementById(mapId);
	if (!map) {
		const el = document.createElement('svg');
		el.setAttribute('width', '0');
		el.setAttribute('height', '0');
		el.className = 'invisible absolute pointer-events-none';
		el.id = mapId;
		document.body.appendChild(el);
		map = el;
	}

	return {
		addIcon(icon: IconName) {
			if (availableIcons.has(icon)) {
				return;
			}

			availableIcons.add(icon);

			const output = icons[icon]();
			map.innerHTML += typeof output === 'string' ? output : output.content;
		},
	};
}

function initServerProvider() {
	const icons = new Set<IconName>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	useAssets(() => ssr(renderIcons(icons)) as any);

	return {
		addIcon(icon: IconName) {
			icons.add(icon);
		},
	};
}

function renderIcons(toRender: Set<IconName>) {
	return `<svg width="0" height="0" class="invisible absolute pointer-events-none" id="${mapId}">
${Array.from(toRender)
	.map((name) => {
		const ret = icons[name]();
		return typeof ret === 'string' ? ret : ret.content;
	})
	.join('')}
</svg>`;
}

type CTX = {
	addIcon: (icon: IconName) => void;
};
const IconContext = createContext<CTX>();

export function IconProvider(props: ParentProps) {
	const actions = !isServer ? initClientProvider() : initServerProvider();
	return <IconContext.Provider value={actions}>{props.children}</IconContext.Provider>;
}

export function Icon(props: IconProps & { name: keyof typeof icons }) {
	if (process.env.NODE_ENV === 'test') {
		// eslint-disable-next-line solid/components-return-once
		return <>{null}</>;
	}

	const ctx = useContext(IconContext);

	createRenderEffect(() => {
		ctx?.addIcon(props.name);
	});

	const [, svgProps] = splitProps(props, ['name']);

	const icon = createMemo(() => {
		const out = icons[props.name]();
		return typeof out === 'string' ? out : out.content;
	});

	return (
		<svg
			{...(typeof icons[props.name]() === 'string'
				? defaultProps
				: ((icons[props.name]() as { content: string; props: Record<string, string> }).props ?? defaultProps))}
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
			innerHTML={!ctx ? icon().replace(/symbol/g, 'g') : `<use href="#icon-${props.name}" />`}
		/>
	);
}

const defaultProps = {
	viewBox: '0 0 24 24',
	width: '1.2em',
	height: '1.2em',
	fill: 'none',
	stroke: 'none',
};
